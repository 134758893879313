import React, { useContext, useEffect, useRef, useState } from "react";
import { Alert, View, StyleSheet, Image, TouchableOpacity } from "react-native";
import { SafeAreaView } from "react-navigation";
import { Text, Button } from "react-native-elements";
import OrderListView from "../../components/Listview/OrderListView";
import BackIcon from "../../images/back_arrow.png";
import { navigate } from "../../utils/NavigationRef";

import { Context as UserContext } from "../../dataStore/userAccessContext";

const OrderScreen = ({ navigation }) => {
  const { state, onViewOrders, onViewOrderDetails } = useContext(UserContext);
  const [isVisible, setIsVisible] = useState(false);
  const [transactionDenied, setTransactionDenied] = useState(false);
  const { params } = navigation.state;

  const { orders, restaurants } = state;

  useEffect(() => {
    if(params && params.newOrder){
      if (params.transaccion && params.transaccion.status != 'APPROVED') {
        setTransactionDenied(true);
      }
      else {
        setIsVisible(true);
      }
    }
    onViewOrders();
  }, []);

  const didSelect = ({ item }) => {
    onViewOrderDetails(item);
  };

  const onCancel = ({ item }) => {
    console.log(item);
  };

  const toggleDialog = () => {
    setIsVisible(!isVisible);
  };


  const didTapBack = () => {
    navigation.goBack();
    navigate("RestaurantDetail", restaurants[0]);
  };

  if(isVisible){
    return(
          <View style={stylesDialog.dialogContainer}>
            <View style={stylesDialog.dialog}>
              <Text style={stylesDialog.dialogTitle}>¡Pedido Confirmado!</Text>
              <Text style={stylesDialog.dialogText}>
                Su pedido se ha registrado exitosamente.
              </Text>
              <TouchableOpacity onPress={toggleDialog} style={stylesDialog.closeButton}>
                <Text style={stylesDialog.closeButtonText}>OK</Text>
              </TouchableOpacity>
            </View>
          </View>
        )
  }

  if(transactionDenied){
    return(
          <View style={stylesDialog.dialogContainer}>
            <View style={stylesDialog.dialog}>
              <Text style={stylesDialog.dialogTitle}>¡Pedido Rechazado!</Text>
              <Text style={stylesDialog.dialogText}>
                El pago de su pedido no ha realizado exitosamente
              </Text>
              <TouchableOpacity onPress={toggleDialog} style={stylesDialog.closeButtonError}>
                <Text style={stylesDialog.closeButtonText}>OK</Text>
              </TouchableOpacity>
            </View>
          </View>
        )
  }

  return (
    <SafeAreaView style={stylesDialog.contentView} forceInset={{ top: "always" }}>
      <View style={styles.titleView}>
        <TouchableOpacity onPress={() => didTapBack()}>
          <Image style={styles.imgIcon} source={BackIcon} />
        </TouchableOpacity>
        <Text h4 style={{ flex: 1, textAlign: "center", marginRight: 40 }}>
          Mis Ordenes
        </Text>
      </View>
      {orders !== undefined && orders.length > 0 ? (
        <View style={styles.listView}>
          <OrderListView
            orders={orders}
            didSelectItem={didSelect}
            onCancel={onCancel}
          />
        </View>
      ) : (
        <View style={styles.listView}>
          <View
            style={{
              display: "grid",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ fontSize: 28, fontWeight: "500", color: "#9C9696" }}>
              Tu lista de ordenes está vacía
            </Text>
          </View>
        </View>
      )}
    </SafeAreaView>
  );
};

const stylesDialog = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    backgroundColor: 'blue',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    marginBottom: 20,
  },
  buttonText: {
    color: 'white',
    fontSize: 16,
  },
  dialogContainer: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialog: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    elevation: 5,
  },
  dialogTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  dialogText: {
    fontSize: 16,
    marginBottom: 20,
  },
  closeButtonError:{
    backgroundColor: '#FFC107',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
  },
  closeButton: {
    backgroundColor: '#3CB371',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
  },
  closeButtonText: {
    color: 'white',
    fontSize: 16,
  },
});

const styles = StyleSheet.create({
  contentView: {
    backgroundColor: "#F2F2F2",
    flex: 1,
    justifyContent: "space-between",
  },
  titleView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    paddingLeft: 20,
    marginTop: 20,
    paddingRight: 20,
  },
  listView: {
    flex: 9,
  },
  bottomView: {
    flex: 2,
  },

  imgIcon: {
    width: 40,
    height: 50,
  },
  searchOptions: {
    display: "flex",
    height: 60,
    justifyContent: "space-around",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 10,
  },
  topCategory: {
    height: 100,
    backgroundColor: "green",
  },

  amountDetails: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    margin: 5,
  },
});

OrderScreen.navigationOptions = () => {
  return {
    header: null,
  };
};

export default OrderScreen;
