import React, { useContext, useEffect, useRef, useState } from "react";
import { Alert, View, StyleSheet, Image, Modal } from "react-native";
import { SafeAreaView } from "react-navigation";
import { Text, Button } from "react-native-elements";

import CartListView from "../../components/Listview/CartListView";
import AppButton from "../../components/Buttons/AppButton";
import { TouchableOpacity } from "react-native-gesture-handler";

import { Context as UserContext } from "../../dataStore/userAccessContext";
import { navigate } from "../../utils/NavigationRef";
import OrderIcon from "../../images/orders.png";
import PaymentTypePopup from "react-native-raw-bottom-sheet";
import ArrowIcon from "../../images/arrow_icon.png";
import BackIcon from "../../images/back_arrow.png";
import ClienteModal from "./clienteModal";
import CryptoJS from 'crypto-js';

const moneda = Intl.NumberFormat('es-CO', { maximumSignificantDigits: 3 });
const CartScreen = ({ navigation }) => {
  const { state, onViewCart, onAddToCart, onCreateOrder, onSaveOrder } = useContext(
    UserContext
  );

  const { cartItems, orders, restaurants } = state;

  const popupRef = useRef();
  const [isLoading, setLoading] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [isOnline, setIsOnline] = useState(false);
  const [cerradoModal, setCerradoModal] = useState(false);
  const [wompis, setWompis] = useState(null);
  const [recoger, setRecoger] = useState(false);

  useEffect(() => {
    console.log(orders);
    console.log("Goto Order Screen");
  }, [orders]);

  useEffect(() => {
    onViewCart();
    const config = JSON.parse(sessionStorage.getItem('configuracion'));
    let all_wp = JSON.parse(config.pasarela_wompi);
    all_wp = all_wp.filter((e) => e.enabled == true);
    setWompis(all_wp);
    if(isClosed()){
      setCerradoModal(true);
    }
  }, []);


  const didTapOrderNow = (tipo = false) => {
    if(tipo){
      setIsOnline(true);
    }
    else{
      setIsOnline(false);
    }
    setLoading(true);
  };

  const generateSHA256 = async(cadenaConcatenada) => {
    const hash = CryptoJS.SHA256(cadenaConcatenada);
    const hashHex = hash.toString(CryptoJS.enc.Hex);
    return hashHex;
  }


   const getCliente = async (cliente) => {
    setLoading(false);
    if (!cliente) {
      return;
    }
    if (isOnline) {
      var actualWompi = {};
      if(wompis && wompis.length > 1){
        if(cliente.tipoDocumento == 'nit'){
          var wp_index = wompis.findIndex((e) => e.electronicInvoice == true);
          if(wp_index != -1){
            actualWompi = wompis[wp_index]
          }
          else {
            actualWompi = wompis[0];
          }
        }
        else{
          var wp_index = wompis.findIndex((e) => e.electronicInvoice == false);
          if(wp_index != -1){
            actualWompi = wompis[wp_index]
          }
          else {
            actualWompi = wompis[0];
          }
        }
      }
      else {
        actualWompi = wompis[0];
      }
      var id = 'Maxx_' + (new Date()).getTime();
      onSaveOrder(id, {...cliente,['address']:recoger ? 'PEDIDO PARA RECOGER' : cliente.address,['address2']:recoger ? '-' : cliente.address2});
      //var id = window.btoa(JSON.stringify(cartItems));
      var totalPagar = totalAmount()*100;
      var cadenaConcatenada = id+totalPagar+"COP"+actualWompi.signature;
      //Ejemplo
      const hashHex = await generateSHA256(cadenaConcatenada);
      console.log(hashHex);
      var configWompi = {
        currency: 'COP',
        amountInCents: totalPagar,
        reference: id,
        publicKey: actualWompi.publicKey,
        signature: {integrity : hashHex},
        //redirectUrl: 'https://transaction-redirect.wompi.co/check', // Opcional
        customerData: { // Opcional
          email: cliente.email ?? "no-email@maxx247.com",
          fullName: cliente.name ?? "N/A",
          phoneNumber: cliente.phone ?? '0',
          phoneNumberPrefix: '+57',
          legalId: cliente.identificacion ? cliente.identificacion : '222222222222',
          legalIdType: 'CC'
        },
        shippingAddress: { // Opcional
          addressLine1: cliente.address ? cliente.address + ' ' + cliente.address2 : "DIRECCION 1234 ",
          city: cliente.cityName ?? "Pereira",
          phoneNumber: cliente.phone ?? '3235028758',
          region: cliente.cityState ?? "Risaralda",
          country: "CO"
        }
      };
      console.log(configWompi);
      var checkout = new WidgetCheckout(configWompi);
      checkout.open(function (result) {
        var transaction = result.transaction;
        console.log("Transaction ID: ", transaction.id);
        console.log("Transaction object: ", transaction);
        if(transaction.status == 'APPROVED'){
          var orders = JSON.parse(localStorage.getItem('orders') ?? "[]");
          var id = transaction.reference;
          var total = 0;
          var carrito = JSON.parse(sessionStorage.getItem("carrito"));
          carrito.map((p) => {
            total += p.precioventaconiva*p.cantidad
          })
          orders.push({
            'id':id,
            'fecha': new Date(),
            'total': total,
            'productos':carrito
          });
          localStorage.setItem("orders",JSON.stringify(orders));
          sessionStorage.setItem("carrito","[]");
        }
        navigate("Order",{newOrder:true, transaccion:transaction});
      });
    }
    else {
      onCreateOrder({...cliente,['address']:recoger ? 'PEDIDO PARA RECOGER' : cliente.address,['address2']:recoger ? '-' : cliente.address2});
    }
  }

  const onAddItem = (item, qty) => {
    onAddToCart(item, qty);
  };

  const onRemoveItem = (item, qty) => {
    onAddToCart(item, qty);
  };

  const totalAmount = () => {
    let total = 0;
    if (cartItems !== undefined && cartItems.length > 0) {
      cartItems.map((item) => {
        let qty = item.cantidad;
        let price = item.precioventaconiva;
        total += qty * price;
      });
    }

    return total;
  };

  function isClosed() {
    const horarios = JSON.parse(
      JSON.parse(sessionStorage.getItem("configuracion")).horarios
    );

    const currentDay = new Date().getDay(); // Día actual de la semana (0 = domingo, 6 = sábado)
    const currentTime = new Date().toTimeString().substring(0, 5); // Hora actual en formato HH:mm

    const todaySchedule = horarios[getDayName(currentDay)]; // Horarios para el día actual
    if (!todaySchedule) return true; // Si no hay horario para el día actual, se considera cerrado

    const { apertura, cierre } = todaySchedule;

    return currentTime < apertura || currentTime > cierre; // Si está fuera del horario, la tienda está cerrada
  }

  function nextOpeningTime() {
      const horarios = JSON.parse(
        JSON.parse(sessionStorage.getItem("configuracion")).horarios
      );

      const currentDay = new Date().getDay();
      const currentTime = new Date().toTimeString().substring(0, 5);

      // Revisar si la tienda abrirá más tarde en el día actual
      const todaySchedule = horarios[getDayName(currentDay)];
      if (todaySchedule && currentTime < todaySchedule.apertura) {
        return `Hoy a las ${todaySchedule.apertura}`;
      }

      // Buscar el próximo día con horario de apertura
      for (let i = 1; i <= 7; i++) {
        const nextDay = (currentDay + i) % 7;
        const nextSchedule = horarios[getDayName(nextDay)];
        if (nextSchedule && nextSchedule.apertura) {
          return `el próximo ${getDayName(nextDay)} a las ${nextSchedule.apertura}`;
        }
      }

      return "próximamente"; // Si no hay horarios configurados
    }

    function getDayName(dayIndex) {
      const days = ["domingo", "lunes", "martes", "miercoles", "jueves", "viernes", "sabado"];
      return days[dayIndex];
    }


    function processPayment(option) {
      if (option === "Pago Electrónico") {
        // Lógica para procesar pagos electrónicos
        console.log("Procesando pago electrónico...");
        didTapOrderNow(true);
        // Redirigir o ejecutar función de pago electrónico aquí
      } else if (option === "Pago Contraentrega") {
        // Lógica para pagos contraentrega
        console.log("Procesando pago contraentrega...");
        didTapOrderNow(false);
        // Ejecutar la lógica de pago contraentrega
      } else if (option === "Pago en Efectivo") {
        // Lógica para pagos en efectivo al recoger en tienda
        console.log("Procesando pago en efectivo al recoger...");
        didTapOrderNow(false);
        // Ejecutar la lógica de pago en efectivo
      }
    }

    function openModalPagar(option){
      if (option === "domicilio") {
        setRecoger(false);
        setPaymentOptions(["Pago Electrónico", "Pago Contraentrega"]);
      } else if (option === "recoger") {
        setRecoger(true);
        setPaymentOptions(["Pago Electrónico", "Pago en Efectivo"]);
      }
      popupRef.current.open()
    }

  if(isLoading){
    return(
      <SafeAreaView style={styles.contentView} forceInset={{ top: "always" }}>
     <ClienteModal
          visible={isLoading}
          onClose={getCliente}
        />
        </SafeAreaView>
      );
  }

  if (cerradoModal) {
    return(
      <SafeAreaView style={stylesCerrado.contentView} forceInset={{ top: "always" }}>
      <Modal
      transparent={true}
      animationType="slide"
      visible={cerradoModal}
      onRequestClose={() => setCerradoModal(false)}
    >
      <View style={stylesCerrado.modalBackground}>
        <View style={stylesCerrado.modalContainer}>
          {/*<Image
            source={BackIcon} // Reemplaza con tu imagen
            style={stylesCerrado.closedImage}
          />*/}
          <Text style={stylesCerrado.modalTitle}>Establecimiento Cerrado</Text>
          <Text style={stylesCerrado.modalMessage}>
            Actualmente nos encontramos cerrados. El próximo horario de apertura es:
          </Text>
          <Text style={stylesCerrado.openingTime}>{nextOpeningTime()}</Text>

          <AppButton
            height={50}
            title="Aceptar"
            onTap={() => setCerradoModal(false)}
            style={stylesCerrado.acceptButton}
          />
        </View>
      </View>
    </Modal>
    </SafeAreaView>
    )
  }

  return (
    <SafeAreaView style={styles.contentView} forceInset={{ top: "always" }}>
      <View style={styles.titleView}>
        <Text h4> Mi Carrito</Text>
        <TouchableOpacity
          style={{ alignItems: "center" }}
          onPress={() => {
            navigate("Order");
          }}
        >
          <Image source={OrderIcon} style={styles.imgIcon} />
        </TouchableOpacity>
      </View>

      <View style={styles.listView}>
        {cartItems !== undefined && cartItems.length > 0 ? (
          <CartListView
            cartItems={cartItems}
            onAddItem={onAddItem}
            onRemoveItem={onRemoveItem}
          />
        ) : (
          <View style={styles.emptyCartView}>
            <Text style={styles.emptyCartText}>
              Tu carrito está vacío
            </Text>
          </View>
        )}
      </View>

      {cartItems !== undefined && cartItems.length > 0 && !isClosed() &&(
        <View style={styles.bottomView}>
          <View style={styles.amountDetails}>
            <Text style={{ fontSize: 18 }}> Total</Text>
            <Text style={{ fontSize: 18, fontWeight: "600" }}>
              $ {moneda.format(totalAmount())}
            </Text>
          </View>

          {/* Button for Continuing */}
          <AppButton
            height={50}
            title="Seguir Pidiendo"
            onTap={() => navigate("RestaurantDetail", restaurants[0])}
            disabled={isClosed()}
          />

          {/* Delivery and Pickup Options in the same row */}
          <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 3 }}>
            <AppButton
              height={50}
              width={150}
              color="#3CB371"
              title="Domicilio"
              style={{ flex: 1, marginRight: 5 }}  // Margin to add space between the buttons
              onTap={() => openModalPagar('domicilio')}
              disabled={isClosed()}
            />
            <AppButton
              width={150}
              height={50}
              color="#3CB371"
              title="Recoger en Tienda"
              style={{ flex: 1, marginLeft: 5 }}  // Margin to add space between the buttons
              onTap={() => openModalPagar("recoger")}
              disabled={isClosed()}
            />
          </View>

        </View>
      )}

      <PaymentTypePopup
        ref={popupRef}
        closeOnDragDown={true}
        closeOnPressMask={false}
        height={200}
        customStyles={{
          wrapper: {
            backgroundColor: "transparent",
          },
          draggableIcon: {
            backgroundColor: "#000",
          },
          container: {
            justifyContent: "flex-start",
            alignItems: "center",
          },
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            width: "99vw",
          }}
        >
          {/*<View style={styles.amountDetails}>
            <Text style={{ fontSize: 18 }}> Total </Text>
            <Text style={{ fontSize: 18, fontWeight: "600" }}>
              $ {moneda.format(totalAmount())}
            </Text>
          </View>*/}

          {/* Payment Options */}
          {paymentOptions.map((option, index) => (
            <TouchableOpacity
              key={index}
              onPress={() => {
                popupRef.current.close();
                processPayment(option);
              }}
            >
              <View style={styles.options}>
                <Text style={styles.optionsText}>{option}</Text>
                <Image source={ArrowIcon} style={styles.icon} />
              </View>
            </TouchableOpacity>
          ))}
        </View>
      </PaymentTypePopup>
    </SafeAreaView>
  );
};

const stylesCerrado = {
  contentView: {
    flex: 'auto',
    justifyContent: "center",
    height: '99vh',
  },
  modalBackground: {
    flex: 'auto',
    justifyContent: "center",
    height: '99vh',
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Fondo semitransparente
  },
  modalContainer: {
    width: "80%",
    backgroundColor: "#fff",
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  closedImage: {
    width: 100,
    height: 100,
    marginBottom: 20,
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#FF6347", // Color atractivo
    marginBottom: 10,
  },
  modalMessage: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 10,
    color: "#333",
  },
  openingTime: {
    fontSize: 18,
    fontWeight: "600",
    color: "#3CB371", // Verde para un buen contraste
    marginBottom: 20,
  },
  acceptButton: {
    marginTop: 20,
  },
};

const styles = StyleSheet.create({
  contentView: {
    backgroundColor: "#F2F2F2",
    flex: 1,
    justifyContent: "space-between",
  },
  titleView: {
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    paddingLeft: 20,
    paddingRight: 20,
  },
  listView: {
    flex: 8,
  },
  bottomView: {
    flex: 3,
  },

  imgIcon: {
    width: 60,
    height: 60,
  },
  searchOptions: {
    display: "flex",
    height: 60,
    justifyContent: "space-around",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 10,
  },
  topCategory: {
    height: 100,
    backgroundColor: "green",
  },

  amountDetails: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    margin: 5,
  },
  options: {
    display: "flex",
    height: 80,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    paddingLeft: 20,
    paddingRight: 20,
    /*borderTopColor: "#DFDFDF",
    borderTopWidth: 0.5,
    borderBottomColor: "#DFDFDF",
    borderBottomWidth: 0.5,*/
  },
  optionsText: {
    fontSize: 18,
  },
  icon: {
    width: 40,
    height: 40,
  },
});

CartScreen.navigationOptions = () => {
  return {
    header: null,
  };
};

export default CartScreen;

/*
<View>
        <View style={styles.searchOptions}>

        </View>

        <View>

        </View>
      </View>*/
