import React, { useContext, useEffect, useState } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  Dimensions,
  ImageBackground,
} from "react-native";
import { SafeAreaView } from "react-navigation";
import { Text, Button } from "react-native-elements";
import BackIcon from "../../images/back_arrow.png";
import SearchIcon from "../../images/search.png";
import ProductListView from "../../components/Listview/ProductListView";
import { urlImage } from "../../utils/AppConst";
import { navigate } from "../../utils/NavigationRef";

import { Context as UserContext } from "../../dataStore/userAccessContext";

const screenWidth = Dimensions.get("window").width;

const GruposDetails = ({ navigation }) => {
  const { onAddToCart, onViewCart } = useContext(UserContext);

  const { params } = navigation.state;

  const {
    descripcion,
    foto,
    direccion,
    foodType,
    productos,
    sucursal,
    idsucursal,
  } = params;

  useEffect(() => {
    onViewCart();
  }, []);

  const didTapBack = () => {
    navigation.goBack();
  };
  const buscarItem = () => {
    navigate("Search");
  };

  const didSelectItem = (item) => {
    if (item.gustos && item.gustos.length > 0) {
      navigate("FoodDetails", item);
    }
  };

  const didAddToCard = (item) => {
    if (item.gustos && item.gustos.length > 0) {
      navigate("FoodDetails", item);
    }
    else {
      onAddToCart(item);
    }
  };

  const didAddRemove = (item, qty) => {
    onAddToCart(item, qty);
  };

  function mostrarFoto() {
    //let input = document.frmConvert.hex.value.replace(/[^A-Fa-f0-9]/g, "");
    if (foto % 2) {
        console.log("cleaned hex string length is odd.");
        return;
    }

    let binary = new Array();
    for (let i = 0; i < foto.length / 2; i++) {
        let h = foto.substr(i * 2, 2);
        binary[i] = parseInt(h, 16);
    }

    let byteArray = new Uint8Array(binary);
    let img = document.querySelector('.heximage');

    return window.URL.createObjectURL(new Blob([byteArray], { type: 'application/octet-stream' }));
    //document.body.appendChild(img)
}


  return (
    <SafeAreaView style={styles.contentView} forceInset={{ top: "always" }}>
      <View style={styles.titleView}>
        <TouchableOpacity onPress={() => didTapBack()}>
          <Image style={styles.imgIcon} source={BackIcon} />
        </TouchableOpacity>
        <Text h4 style={{ flex: 1, textAlign: "center", marginRight: 40 }}>
          {descripcion}
        </Text>
        <TouchableOpacity onPress={() => buscarItem()}>
          <Image style={styles.searchIcon} source={SearchIcon} />
        </TouchableOpacity>
      </View>
      <View style={styles.listView}>
        <ImageBackground
          source={{
            uri: foto//mostrarFoto(),
          }}
          style={styles.coverImage}
        >
          <View style={styles.foodInfo}>
            <Text
              style={{
                fontSize: 18,
                color: "#FFF",
                fontWeight: "500",
                marginTop: 5,
                textAlign:"center"
              }}
            >
              ELIGE TUS PRODUCTOS FAVORITOS
            </Text>
          </View>
        </ImageBackground>
        <ProductListView
          size="small"
          disable={false}
          foods={productos}
          didSelectItem={didSelectItem}
          didAddToCart={didAddToCard}
          didAddRemove={didAddRemove}
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  contentView: {
    backgroundColor: "#F2F2F2",
    flex: 1,
    justifyContent: "space-between",
  },
  titleView: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  listView: {
    flex: 11,
  },
  coverImage: {
    width: screenWidth,
    height: 220,
    justifyContent: "flex-end",
  },

  imgIcon: {
    width: 40,
    height: 50,
  },
  searchIcon: {
    width: 30,
    height: 30,
  },
  searchOptions: {
    display: "flex",
    height: 60,
    justifyContent: "space-around",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 10,
  },
  topCategory: {
    height: 100,
    backgroundColor: "green",
  },

  amountDetails: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    margin: 5,
  },
  foodTitle: {
    color: "white",
    fontWeight: "700",
  },
  foodInfo: {
    height: 60,
    backgroundColor: "rgba(0,0,0,0.6)",
    padding: 10,
  },
  foodDetails: {
    fontSize: 18,
    color: "white",
    fontWeight: "400",
  },
});

GruposDetails.navigationOptions = () => {
  return {
    header: null,
  };
};

export default GruposDetails;
