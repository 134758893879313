import { AsyncStorage } from "react-native";
import { navigate } from "../utils/NavigationRef";
import createAppContext from "./createAppContext";
import API from "../api/WebService";
import aType from "../utils/ActionTypes";
import userReducer from "./reducer";

/**
 * User Actions
 */

 const parseURLParams = () => {
    var url = window.location.search;
    var queryStart = url.indexOf("?") + 1,
        queryEnd   = url.indexOf("#") + 1 || url.length + 1,
        query = atob(url.slice(queryStart, queryEnd - 1)),
        pairs = query.replace(/\+/g, " ").split(","),
        parms = {}, i, n, v, nv;
    if (query === url || query === "") return;
    for (i = 0; i < pairs.length; i++) {
        nv = pairs[i].split("*", 2);
        n = decodeURIComponent(nv[0]);
        v = decodeURIComponent(nv[1]);
        if (!parms.hasOwnProperty(n)) parms[n] = [];
        parms[n].push(nv.length === 2 ? v : null);
    }
    return parms;
}

const onCheckAvailability = (dispatch) => async () => {
  try {
    const response = await API.get("/food");
    dispatch({ type: aType.ALL_FOODS, payload: response.data });
  } catch {
    dispatch({ type: aType.ERROR, payload: "Data Not found" });
  }
};



const fetchTopRestaurants = (dispatch) => async () => {
  var isEmpresa = false;
  try {
    var data = parseURLParams();
    var token = data['token'][0] ?? sessionStorage.getItem('token');
    var idsucursal = data['idsucursal'][0] ?? sessionStorage.getItem('idsucursal');
    const validacion = await API.get("/validate",{
    headers: {
      'Authorization': token
    }
    });
    sessionStorage.setItem('token',token);
    sessionStorage.setItem('idsucursal',(idsucursal ?? 1));
    //console.log(validacion);
    sessionStorage.setItem('configuracion',(JSON.stringify(validacion.data.configuraciones ?? [])));
    isEmpresa = true;
    if (isEmpresa) {
      var token = sessionStorage.getItem("token");
      const response = await API.get("/restaurantes?idsucursal="+(idsucursal ?? 1),{
      headers: {
        'Authorization': token
      }
      });
      dispatch({ type: aType.TOP_RESTAURANTS, payload: response.data });
    }
    else {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('idsucursal');
      dispatch({ type: aType.ERROR, payload: "Data Not found" });
    }
  } catch(e) {
    sessionStorage.removeItem('idsucursal');
    sessionStorage.removeItem('token');
    dispatch({ type: aType.ERROR, payload: "Data Not found" });
  }
};

const onViewCart = (dispatch) => () => {
  var carrito = JSON.parse(sessionStorage.getItem("carrito") ?? "[]");
  dispatch({ type: aType.VIEW_CART, payload: carrito });
};

const onAddGusto = (dispatch) => (item, gusto, value) => {
  var carrito = JSON.parse(sessionStorage.getItem("carrito") ?? "[]");
  var prodIndex = carrito.findIndex((e) => e.idproducto == item.idproducto);
  var gustosTemp = [];
  var gustos = item.gustos ?? [];
  if (prodIndex != -1) {
    gustosTemp = carrito[prodIndex].gustos_selected ?? [];
  }
  if (!value) {
    indexRemove = gustosTemp.findIndex((e) => e.codigo == gusto);
    gustosTemp.splice(indexRemove,1);
  }
  else {
    //gustos = prodIndex !== -1 ? (carrito[prodIndex].gustos ?? []) : gustos;
    var gustoIndex = gustos.findIndex((e) => e.codigo == gusto);
    gustosTemp.push(gustos[gustoIndex]);
  }
  if (prodIndex != -1) {
    var newProducto = {
      ...carrito[prodIndex],
      gustos_selected: gustosTemp
    }
    carrito.splice(prodIndex,1,newProducto);
  }
  else {
    var newProducto = {
      ...item,
      gustos_selected: gustosTemp,
      cantidad: 1
    }
    carrito.push(newProducto);
  }

  sessionStorage.setItem('carrito', JSON.stringify(carrito));
  dispatch({ type: aType.VIEW_CART, payload: carrito });
}

const onAddToCart = (dispatch) => (item, qty, isNew) => {
  var carrito = JSON.parse(sessionStorage.getItem("carrito") ?? "[]");
  if (qty == 0) {
    var prodIndex = carrito.findIndex((e) => e.idproducto == item.idproducto);
    if (prodIndex != -1) {
      carrito.splice(prodIndex,1);
    }
  }
  else if (qty !== undefined) {
    if(!isNew){
      var prodIndex = carrito.findIndex((e) => e.idproducto == item.idproducto);
      var producto = {...carrito[prodIndex],cantidad:qty};
      if (prodIndex != -1) {
        carrito.splice(prodIndex,1,producto);
      }
      else {
        var producto = {...item,cantidad:qty};
        carrito.push(producto);
      }
    }
    else{
      var producto = {...item,cantidad:qty};
      carrito.push(producto);
    }

  }
  else {
    var prodIndex = carrito.findIndex((e) => e.idproducto == item.idproducto);
    if (prodIndex != -1) {
      var producto = {...carrito[prodIndex],cantidad:1};
      carrito.splice(prodIndex,1,producto);
    }
    else {
      var producto = {...item,cantidad:qty};
      carrito.push(producto);
    }
  }
  sessionStorage.setItem('carrito', JSON.stringify(carrito));
  dispatch({ type: aType.VIEW_CART, payload: carrito });
};

function limpiarJSON(cart, name){
  cart = cart.map((jsonVar) => {
    delete jsonVar['combos'];
    delete jsonVar['gustos'];
    delete jsonVar['foto'];

    jsonVar.combos_selected.map((combo) => {
      delete combo['foto'];
      return combo;
    });
    return jsonVar;
  });

  return cart;
}

const returnFotoProducto = (dispatch) => async (productoid) => {
  var token = sessionStorage.getItem("token");

  try {
    // La función debe ser async para usar await
    const response = await API.get(`/productos/imagen?productoid=${productoid}`, {
      headers: {
        'Authorization': token
      }
    });

    // Retorna la imagen o cualquier otro dato necesario
    return response.data;

  } catch (err) {
    // Manejo de errores
    console.error("Error al obtener la foto del producto:", err);
    return null; // Devuelve null o un valor por defecto en caso de error
  }
};

const returnFotoGrupo = (dispatch) => async (grupoid) => {
  var token = sessionStorage.getItem("token");

  try {
    // La función debe ser async para usar await
    const response = await API.get(`/grupos/imagen?grupoid=${grupoid}`, {
      headers: {
        'Authorization': token
      }
    });

    // Retorna la imagen o cualquier otro dato necesario
    return response.data;

  } catch (err) {
    // Manejo de errores
    console.error("Error al obtener la foto del producto:", err);
    return null; // Devuelve null o un valor por defecto en caso de error
  }
};

const onSaveOrder = (dispatch) => (reference_id, cliente) => {
  var carrito = limpiarJSON(JSON.parse(sessionStorage.getItem("carrito")));
  var token = sessionStorage.getItem("token");

  API.post("/invoice/wompi",
  {
    cliente: cliente,
    carrito: carrito,
    reference_id:reference_id,
    idsucursal: sessionStorage.getItem("idsucursal") ?? 1
  },
  {
    headers: {
      'Authorization': token
    }
  })
    .then((response) => {
      //dispatch({ type: aType.CREATE_ORDER, payload: response.data });
      console.log(response)
      //navigate("Order",{newOrder:true});
    })
    .catch((err) => {
      dispatch({ type: aType.ERROR, payload: "Data Not found" });
    });
}

const onCreateOrder = (dispatch) => (cliente) => {
  var carrito = JSON.parse(sessionStorage.getItem("carrito"));
  var token = sessionStorage.getItem("token");
  API.post("/invoice/cash",
  {
    cliente: cliente,
    carrito: carrito,
    idsucursal: sessionStorage.getItem("idsucursal") ?? 1
  },
  {
    headers: {
      'Authorization': token
    }
  })
    .then((response) => {
      //dispatch({ type: aType.CREATE_ORDER, payload: response.data });
      var orders = JSON.parse(localStorage.getItem('orders') ?? "[]");
      var id = 'Maxx_' + (new Date()).getTime();
      var total = 0;
      carrito.map((p) => {
        total += p.precioventaconiva*p.cantidad
      })
      orders.push({
        'id':id,
        'fecha': new Date(),
        'total': total,
        'productos':carrito
      });
      localStorage.setItem("orders",JSON.stringify(orders));
      sessionStorage.setItem("carrito","[]");
      navigate("Order",{newOrder:true});
    })
    .catch((err) => {
      dispatch({ type: aType.ERROR, payload: "Data Not found" });
    });
};

const onViewOrders = (dispatch) => () => {
  /*API.get("/user/order")
    .then((response) => {
      dispatch({ type: aType.VIEW_ORDER, payload: response.data });
    })
    .catch((err) => {
      dispatch({ type: aType.ERROR, payload: "Data Not found" });
    });*/

    var orders = JSON.parse(localStorage.getItem('orders') ?? "[]");
    dispatch({ type: aType.VIEW_ORDER, payload: orders });
};

const onViewOrderDetails = (dispatch) => ({ id }) => {
  /*API.get("/user/order/" + _id)
    .then((response) => {
      dispatch({ type: aType.ORDER_DETAILS, payload: response.data });
      navigate("OrderDetails");
    })
    .catch((err) => {
      dispatch({ type: aType.ERROR, payload: "Data Not found" });
    });*/
    var orders = JSON.parse(localStorage.getItem('orders') ?? "[]");
    var orden  = orders.find((e) => e.id == id);
    if (orden) {
      dispatch({ type: aType.ORDER_DETAILS, payload: orden });
    }

};

const onSignup = (dispatch) => async ({
  email,
  password,
  firstName,
  lastName,
}) => {
  API.post("user/signup", {
    email,
    password,
    firstName,
    lastName,
  })
    .then((response) => {
      configureAPI({ token: `Bearer ${response.data}` });
      dispatch({ type: aType.LOGIN, payload: response.data });
      navigate("homeStack");
    })
    .catch((err) => {
      dispatch({
        type: aType.ERROR,
        payload: "Login Fail with provided Email ID and Password",
      });
    });
};

const onSignin = (dispatch) => async ({ email, password }) => {
  API.post("user/login", {
    email,
    password,
  })
    .then((response) => {
      configureAPI({ token: `Bearer ${response.data}` });
      dispatch({ type: aType.LOGIN, payload: response.data });
      navigate("homeStack");
    })
    .catch((err) => {
      dispatch({
        type: aType.ERROR,
        payload: "Login Fail with provided Email ID and Password",
      });
    });
};

const configureAPI = ({ token }) => {
  API.defaults.headers.common["Authorization"] = token;
};

const onCheckLogin = (dispatch) => async () => {
  const token = await AsyncStorage.getItem("token");
  if (token) {
    dispatch({ type: aType.LOGIN, payload: token });
    navigate("homeStack");
    configureAPI({ token });
  } else {
    navigate("homeStack");
  }
};

const onGetProfile = (dispatch) => async () => {
  try {
  } catch {}
};

const onLogout = (dispatch) => () => {
  navigate("loginStack");
  dispatch({ type: aType.LOGOUT });
};
const onDissmiss = (dispatch) => () => {
  dispatch({ type: aType.DISSMISS });
};

/**
 * Export Methods with Create Context
 */
export const { Provider, Context } = createAppContext(
  userReducer,
  {
    onCheckAvailability,
    onCheckLogin,
    onSignup,
    onSignin,
    onLogout,
    fetchTopRestaurants,
    returnFotoProducto,
    returnFotoGrupo,
    onAddToCart,
    onAddGusto,
    onViewCart,
    onCreateOrder,
    onSaveOrder,
    onViewOrders,
    onViewOrderDetails,
    onDissmiss,
  },
  { accessToken: null, msg: null }
);
